import jwt from 'jsonwebtoken';
//import { parse } from 'path';

export const TOKEN_KEY = "cobcloud";

require('dotenv').config()

const crypto = require('crypto');
const algorithm = 'aes-256-cbc';
const key = process.env.REACT_APP_KEY;
 
export const encrypt = (text) =>{
  let cipher = crypto.createCipher(algorithm,key)
  let crypted = cipher.update(text,'utf8','hex')
  crypted += cipher.final('hex');

  return crypted;
}

export const decrypt = (text) =>{
  let decipher = crypto.createDecipher(algorithm,key)
  let dec = decipher.update(text,'hex','utf8')
  dec += decipher.final('utf8');
  return dec;
};

export const isAuthenticated = () => {
    const token = getToken();

    if (token !== null) {
        try{
            const token_decoded = jwt.verify(token, key);
            let subtoken_cobcloud = decrypt(token_decoded.sub);
            if (subtoken_cobcloud === "cobcloud") {
              //console.log(token_decoded.uid);
              return true;
            }
            else {
              return false;
            }
        }catch{
            console.log('Token Error');
            return false;
        }        
    }else{
        console.log('Token Null');
        return false;
    }
};

export const getIdUser = () => {
  const token = getToken();

  if (token !== null) {
      try{
          const token_decoded = jwt.verify(token, key);
          let subtoken_cobcloud = decrypt(token_decoded.sub);
          if (subtoken_cobcloud === "cobcloud") {
            return token_decoded.uid;
          }
          else {
            return 0;
          }
      }catch{
          return 0;
      }        
  }else{
      return 0;
  }
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('persist:root');
};