const INITIALSTATE = {
    newListProcAtivos: [],
    newFiltroNomeProcAtivos: '',
    newSizeListProcAtivos: 10,
    newPageListProcAtivos: 0,
    newCheckProc: false
}

export const negociadoresReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'UPDATE_LIST_PROC_ATIVOS':
            return { ...state, newListProcAtivos: action.newListProcAtivos };
        case 'CLICK_PAGE_LIST_PROC_ATIVOS':
            return { ...state, newPageListProcAtivos: action.newPageListProcAtivos };
        case 'CLICK_SIZE_LIST_PROC_ATIVOS':
            return { ...state, newSizeListProcAtivos: action.newSizeListProcAtivos };
        case 'CLICK_FILTRO_NOME_PROC_ATIVOS':
            return { ...state, newFiltroNomeProcAtivos: action.newFiltroNomeProcAtivos };
        case 'CLICK_CHECK':
            return { ...state, newCheckProc: action.newCheckProc };
        default:
            return state;
    }
}