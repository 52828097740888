import { credoresReducer } from '../views/Credores/credoresReducer';
import { negociadoresReducer } from '../views/Negociadores/NegociadoresReducer';
import { loginReducer } from '../views/Pages/Login/loginReducer';

import { combineReducers } from 'redux';

const allReducers = combineReducers({
  stateCredores: credoresReducer, 
  stateNegociadores: negociadoresReducer,
  stateLogin: loginReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  else if (action.type === 'LIMPA_ESTADO') {
    //
  }

  return allReducers(state, action);
}