let dataHoje = new Date();
let anoAtual = dataHoje.getFullYear();
let mesAtual = dataHoje.getMonth() + 1;

const initialState = {
  newFiltro: '45',
  newList: [],
  newSizeList: 10,
  newPageList: 0,
  newFiltroComercial: null,
  newFiltroGrupoSemRec: null,

  newListRem: [],
  newSizeListRem: 10,
  newPageListRem: 0,
  newFiltroAnoRem: anoAtual,
  newFiltroMesRem: [mesAtual],
  newFiltroGrupoRem: null,
  newFiltroRazaoRem: '',
  newFiltroComercialRem: null,

  newListRemAnual: [],
  newSizeListRemAnual: 10,
  newPageListRemAnual: 0,
  newFiltroAnoRemAnual: anoAtual,
  newFiltroGrupoRemAnual: null,
  newFiltroRazaoRemAnual: '',
  newFiltroComercialRemAnual: null,

  newListRec: [],
  newSizeListRec: 10,
  newPageListRec: 0,
  newFiltroAnoRec: anoAtual,
  newFiltroMesRec: [mesAtual],
  newFiltroGrupoRec: null,
  newFiltroRazaoRec: '',
  newFiltroComercialRec: null,

  newListCad: [],
  newSizeListCad: 10,
  newPageListCad: 0,
  newFiltroGrupoCad: null,
  newFiltroRazaoCad: '',
  newFiltroComercialCad: null,
};

export const credoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLICK_UPDATE_FILTRO':
      return { ...state, newFiltro: action.newFiltro };
    case 'CLICK_UPDATE_LIST':
      return { ...state, newList: action.newList };
    case 'CLICK_UPDATE_SIZE_LIST':
      return { ...state, newSizeList: action.newSizeList };
    case 'CLICK_UPDATE_PAGE_LIST':
      return { ...state, newPageList: action.newPageList };
    case 'CLICK_UPDATE_FILTRO_COMERCIAL':
      return { ...state, newFiltroComercial: action.newFiltroComercial };
    case 'CLICK_FILTRO_GRUPO_SEM_REC':
      return { ...state, newFiltroGrupoSemRec: action.newFiltroGrupoSemRec };

    //remuneracao
    case 'UPDATE_LIST_REM':
      return { ...state, newListRem: action.newListRem };
    case 'CLICK_SIZE_LIST_REM':
      return { ...state, newSizeListRem: action.newSizeListRem };
    case 'CLICK_PAGE_LIST_REM':
      return { ...state, newPageListRem: action.newPageListRem };
    case 'CLICK_FILTRO_ANO_REM':
      return { ...state, newFiltroAnoRem: action.newFiltroAnoRem };
    case 'CLICK_FILTRO_MES_REM':
      return { ...state, newFiltroMesRem: action.newFiltroMesRem };
    case 'CLICK_FILTRO_GRUPO_REM':
      return { ...state, newFiltroGrupoRem: action.newFiltroGrupoRem };
    case 'CLICK_FILTRO_RAZAO_REM':
      return { ...state, newFiltroRazaoRem: action.newFiltroRazaoRem };
    case 'CLICK_FILTRO_COMERCIAL_REM':
      return { ...state, newFiltroComercialRem: action.newFiltroComercialRem };

       //remuneracao Anual
    case 'UPDATE_LIST_REM_ANO':
      return { ...state, newListRemAnual: action.newListRemAnual };
    case 'CLICK_SIZE_LIST_REM_ANO':
      return { ...state, newSizeListRemAnual: action.newSizeListRemAnual };
    case 'CLICK_PAGE_LIST_REM_ANO':
      return { ...state, newPageListRemAnual: action.newPageListRemAnual };
    case 'CLICK_FILTRO_ANO_REM_ANO':
      return { ...state, newFiltroAnoRemAnual: action.newFiltroAnoRemAnual };
    case 'CLICK_FILTRO_GRUPO_REM_ANO':
      return { ...state, newFiltroGrupoRemAnual: action.newFiltroGrupoRemAnual };
    case 'CLICK_FILTRO_RAZAO_REM_ANO':
      return { ...state, newFiltroRazaoRemAnual: action.newFiltroRazaoRemAnual };
    case 'CLICK_FILTRO_COMERCIAL_REM_ANO':
      return { ...state, newFiltroComercialRemAnual: action.newFiltroComercialRemAnual };

    //recuperacao
    case 'UPDATE_LIST_REC':
      return { ...state, newListRec: action.newListRec };
    case 'CLICK_SIZE_LIST_REC':
      return { ...state, newSizeListRec: action.newSizeListRec };
    case 'CLICK_PAGE_LIST_REC':
      return { ...state, newPageListRec: action.newPageListRec };
    case 'CLICK_FILTRO_ANO_REC':
      return { ...state, newFiltroAnoRec: action.newFiltroAnoRec };
    case 'CLICK_FILTRO_MES_REC':
      return { ...state, newFiltroMesRec: action.newFiltroMesRec };
    case 'CLICK_FILTRO_GRUPO_REC':
      return { ...state, newFiltroGrupoRec: action.newFiltroGrupoRec };
    case 'CLICK_FILTRO_RAZAO_REC':
      return { ...state, newFiltroRazaoRec: action.newFiltroRazaoRec };
    case 'CLICK_FILTRO_COMERCIAL_REC':
      return { ...state, newFiltroComercialRec: action.newFiltroComercialRec };

    case 'UPDATE_LIST_CAD':
      return { ...state, newListCad: action.newListCad };
    case 'CLICK_SIZE_LIST_CAD':
      return { ...state, newSizeListCad: action.newSizeListCad };
    case 'CLICK_PAGE_LIST_CAD':
      return { ...state, newPageListCad: action.newPageListCad };
    case 'CLICK_FILTRO_GRUPO_CAD':
      return { ...state, newFiltroGrupoCad: action.newFiltroGrupoCad };
    case 'CLICK_FILTRO_RAZAO_CAD':
      return { ...state, newFiltroRazaoCad: action.newFiltroRazaoCad };
    case 'CLICK_FILTRO_COMERCIAL_CAD':
      return { ...state, newFiltroComercialCad: action.newFiltroComercialCad };

    default:
      return state;
  }
};