const initialState = {
    newEmpresa: 0,
    newEmpresaRazao: ''
  };
  
  export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_EMPRESA':
        return {...state, newEmpresa: action.newEmpresa};
      case 'UPDATE_EMPRESA_RAZAO':
        return {...state, newEmpresaRazao: action.newEmpresaRazao};        
      default:
        return state;
    }
  };