import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { isAuthenticated } from "./services/auth";


const loading = () => <div className="animated fadeIn pt-3 text-center">Carregando...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
//const Register = React.lazy(() => import('./views/Pages/Register'));
//const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

//<Component {...props} />

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (        
        <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>                    
            <Switch>                          
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />              
              <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>    
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
