import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { rootReducer } from '../reducers';
export const Store = createStore(rootReducer);

require('dotenv').config()

const encryptor = createEncryptor({
    secretKey: process.env.REACT_APP_KEY,
    onError: function(error) {
      // Handle the error.
    }
  })

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    transforms: [encryptor],
    blacklist: ['stateCredores', 'stateNegociadores'],
    whitelist: ['stateLogin']
  }
  
const persistedReducer = persistReducer(
    persistConfig, 
    rootReducer
  )
  
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { persistor, store };
